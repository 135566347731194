<template>
  <section class="header">
    <div class="full-width overlay">
      <h1>
        <strong>Amsterdam</strong>
        <br>Skills
        <br>Centre
      </h1>
    </div>
    <div class="actions">
      <button @click="handleSave">save</button>
      <!-- button @click="handleSubmit">submit</button -->
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    handleSave() {
      this.$emit("handle-save");
    },
    handleSubmit() {
      this.$emit("handle-submit");
    }
  }
};
</script>


<style lang="scss">
section.header {
  background-image: url(~@/assets/img/amsterdam-skills-centre.jpg);
  background-position: 50%;
  height: 330px;
  background-size: cover;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  width: calc(100% - 40px);
  margin-left: 40px;
  -ms-flex-pack: left;
  justify-content: left;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  border-bottom-left-radius: 80px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    background-color: rgba(28, 58, 83, 0.9);
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 24px;
    margin-bottom: 32px;
    position: relative;
  }
  .overlay {
    margin-left: 20%;
  }
  .actions {
    margin-right: 20%;
    position: relative;
    button {
      background: transparent;
      border: 1px solid #fff;
      width: 150px;
      height: 60px;
      border-radius: 5px;
      color: #fff;
      margin: 12px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
</style>
