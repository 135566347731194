import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate' //https://vee-validate.logaretm.com/v2/guide/rules.html
import ToggleButton from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import VModal from 'vue-js-modal' //https://euvl.github.io/vue-js-modal/Installation.html
import axios from 'axios' //https://www.npmjs.com/package/vue-axios
import VueAxios from 'vue-axios'
import VTooltip from 'v-tooltip'

require("@/assets/scss/main.scss")

Vue.use(VeeValidate, {
  useConstraintAttrs: false
})
Vue.use(require('vue-moment')) //https://www.npmjs.com/package/vue-moment
Vue.use(ToggleButton)
Vue.use(VModal)
Vue.use(require('vue-scrollto')) //https://www.npmjs.com/package/vue-scrollto
Vue.use(VueAxios, axios)
Vue.use(VTooltip)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
