<template>
  <div id="app">
    <modal name="acceptModal" height="300" width="500" classes="accept-modal" :clickToClose="false">
      <h2>By using this form you must accept our terms & conditions</h2>
      <br>
      <a href="https://www.asc.amsterdam/terms" target="_blank">https://www.asc.amsterdam/terms</a>
      <br>
      <br>
      <br>
      <button class="asc-btn" @click="$modal.hide('acceptModal')">Accept terms & conditions</button>
    </modal>
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$modal.show("acceptModal");
  }
};
</script>

<style lang="scss">
.accept-modal {
  text-align: center;
  padding: 20px;
  a {
    color: #1c3a53;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>

